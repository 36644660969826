// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var animatebottom = "NewsArticlePage-module--animatebottom--ys65o";
export var authorContainer = "NewsArticlePage-module--authorContainer--jWo30";
export var authorImageContainer = "NewsArticlePage-module--authorImageContainer--bVXgQ";
export var authorInfo = "NewsArticlePage-module--authorInfo--QasOG";
export var authorInitials = "NewsArticlePage-module--authorInitials--sMOQ3";
export var authorName = "NewsArticlePage-module--authorName--TiLry";
export var authorPosition = "NewsArticlePage-module--authorPosition--RDx6b";
export var becauseWeCareIcon = "NewsArticlePage-module--becauseWeCareIcon--u5Mbb";
export var becauseWeCareInfoContainer = "NewsArticlePage-module--becauseWeCareInfoContainer--1-xtI";
export var becauseWeCareInfoIcon = "NewsArticlePage-module--becauseWeCareInfoIcon--MS6Nx";
export var becauseWeCarePillar = "NewsArticlePage-module--becauseWeCarePillar--qR8P9";
export var becauseWeCarePillarTitle = "NewsArticlePage-module--becauseWeCarePillarTitle--hDXou";
export var becauseWeCarePillarsContainer = "NewsArticlePage-module--becauseWeCarePillarsContainer--5AzCi";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var body = "NewsArticlePage-module--body--ubQ9m";
export var bottomShareButton = "NewsArticlePage-module--bottomShareButton--eBhmf";
export var categoryIcon = "NewsArticlePage-module--categoryIcon--lQbV1";
export var categoryTitle = "NewsArticlePage-module--categoryTitle--+YvXt";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "NewsArticlePage-module--date--QDb1g";
export var dateAndCategoryContainer = "NewsArticlePage-module--dateAndCategoryContainer--H46zR";
export var detailsContainer = "NewsArticlePage-module--detailsContainer--yMXvx";
export var error = "#f66a83";
export var footerLink = "NewsArticlePage-module--footerLink--R0XQk";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconLeft = "NewsArticlePage-module--iconLeft--Qi9Kz";
export var iconRight = "NewsArticlePage-module--iconRight--DEVX5";
export var image = "NewsArticlePage-module--image--jHbK1";
export var imageContainer = "NewsArticlePage-module--imageContainer--z-TKQ";
export var imageWrapper = "NewsArticlePage-module--imageWrapper--fB8QO";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "NewsArticlePage-module--link--et11R";
export var noAuthorImage = "NewsArticlePage-module--noAuthorImage--vnmGU";
export var noAuthorImageContainer = "NewsArticlePage-module--noAuthorImageContainer--NN9cb";
export var orange = "#fc9948";
export var overlay = "NewsArticlePage-module--overlay--Rg5Kd";
export var paleBlue = "#64a1bb";
export var path = "NewsArticlePage-module--path--NHedG";
export var pathContainer = "NewsArticlePage-module--pathContainer--3LCTb";
export var pathTitle = "NewsArticlePage-module--pathTitle--ixLkI";
export var section = "NewsArticlePage-module--section--pCGLT";
export var seeTranslatedArticlesIcon = "NewsArticlePage-module--seeTranslatedArticlesIcon--QVzZH";
export var seeTranslatedArticlesLink = "NewsArticlePage-module--seeTranslatedArticlesLink--3fZPY";
export var seeTranslatedArticlesLinkContainer = "NewsArticlePage-module--seeTranslatedArticlesLinkContainer--0tsXz";
export var shareButtonBottomContainer = "NewsArticlePage-module--shareButtonBottomContainer--VIfg-";
export var shareCardWrapper = "NewsArticlePage-module--shareCardWrapper--BZvzr";
export var shareLink = "NewsArticlePage-module--shareLink--ZqlR4";
export var success = "#1ebc7b";
export var tagContainer = "NewsArticlePage-module--tagContainer--Y4Rro";
export var title = "NewsArticlePage-module--title--VL+Qc";
export var titleParagraph = "NewsArticlePage-module--titleParagraph---WmZ3";
export var topLinksContainer = "NewsArticlePage-module--topLinksContainer--XIejG";
export var untranslatedBarContainer = "NewsArticlePage-module--untranslatedBarContainer--zD+lE";
export var untranslatedBarTextContainer = "NewsArticlePage-module--untranslatedBarTextContainer--5oNUz";
export var untranslatedText = "NewsArticlePage-module--untranslatedText--zEWVr";
export var warning = "#ffeda0";
export var white = "#fff";
export var withAuthor = "NewsArticlePage-module--withAuthor--Ziazb";
export var yellow = "#fbc900";