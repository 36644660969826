import React, { useState } from 'react';
import usePortal from 'react-useportal';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FaChevronRight, FaQuestion, FaShare, FaTag } from 'react-icons/fa';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';

import { RawPortableText } from '../types';
import Layout from '../components/Layout';
import GenericLink from '../components/GenericLink';
import ShareCard from '../components/ShareCard';
import {
  EmbeddedImageSerializer,
  GenericLinkSerializer,
  ImageGallerySerializer,
  NewsArticleLinkSerializer,
  PageLinkSerializer,
  QuoteSerializer,
  ResourceFileSerializer,
  YoutubeSerializer,
} from '../serializers';
// import ArticlesPagination from '../components/ArticlesPagination';
import { getFirstModuleBreadcrumbValuesByFunction } from '../utils/utils';
import { PageBreadcrumbValues } from '../graphql-fragments/pageBreadCrumbValues';

import * as styles from './NewsArticlePage.module.scss';
import { withI18n } from '../utils/hocs';
import { useLocalization } from '../utils/hooks';
import PageSEO from '../components/PageSEO';
import LocalizedLink from '../components/LocalizedLink';
import { useTranslation } from 'react-i18next';
import { LANG_TO_LANG_NAME } from '../components/LanguageSelector';
import becauseWeCare_plants from '../assets/images/graphic_elements/becauseWeCare_plants.svg';
import becauseWeCare_people from '../assets/images/graphic_elements/becauseWeCare_people.svg';
import becauseWeCare_planet from '../assets/images/graphic_elements/becauseWeCare_planet.svg';
import { getReferenceUrl } from '../utils/utils';

interface Author {
  name: string;
  position: string;
  image?: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

type ExtraModuleProperties = {
  showOnlyAFewLatestArticles: boolean;
  newsContentTypes?: Array<{
    _id: string;
  }>;
};

interface LocalizedQueryData {
  allSanityPage: {
    nodes: Array<PageBreadcrumbValues<ExtraModuleProperties>>;
  };
  sanityMediaSettings: {
    becauseWeCarePage:
      | {
          __typename: 'SanityPage';
          slug: {
            current: string;
          };
          contentType?: never;
        }
      | {
          __typename: 'SanityNewsArticle';
          slug: {
            current: string;
          };
          contentType: {
            slug: {
              current: string;
            };
          };
        };
  };
  sanityNewsArticle: {
    image: {
      asset: {
        url: string;
        gatsbyImageData: IGatsbyImageData;
      };
    };
    slug: {
      current: string;
    };
    title: string;
    contentType: {
      _id: string;
      title: string;
    };
    category: {
      title: string;
    };
    becauseWeCarePillars?: Array<'plants' | 'people' | 'planet'>;
    date: string;
    author?: Author;
    extract: string;
    newsArticlePortableText: RawPortableText;
  };
}
interface NewsArticlePageProps {
  data: any;
}

export const query = graphql`
  # -444f41d0-18e2-55db-8fca-44fd6229a7e8
  query GetNewsArticleById($id: String!) {
    allSanityPage {
      nodes {
        sections {
          name {
            ...LocaleStringFragment
          }
          modules {
            __typename
            ... on SanityNewsModule {
              showOnlyAFewLatestArticles
              newsContentTypes {
                _id
              }
            }
          }
        }
        title {
          ...LocaleStringFragment
        }
        slug {
          current
        }
      }
    }
    sanityMediaSettings {
      becauseWeCarePage {
        __typename
        ... on SanityPage {
          slug {
            current
          }
        }
        ... on SanityNewsArticle {
          slug {
            current
          }
          contentType {
            slug {
              current
            }
          }
        }
      }
    }
    sanityNewsArticle(id: { eq: $id }) {
      image {
        ...NewsArticleImageFragment
      }
      slug {
        current
      }
      title {
        ...LocaleStringFragment
      }
      contentType {
        _id
        title {
          ...LocaleStringFragment
        }
      }
      category {
        title {
          ...LocaleStringFragment
        }
      }
      becauseWeCarePillars
      date
      author {
        name
        position {
          ...LocaleStringFragment
        }
        image {
          asset {
            url
            gatsbyImageData(
              layout: CONSTRAINED
              width: 120
              height: 120
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      extract {
        ...LocaleTextFragment
      }
      newsArticlePortableText {
        ...LocaleArticlePortableText
      }
    }
  }
`;

const NewsArticlePage = ({ data }: NewsArticlePageProps): React.ReactElement => {
  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const {
    sanityNewsArticle: newsArticle,
    sanityMediaSettings: mediaSettings,
    allSanityPage: pages,
  } = localizedData;

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Use data props because the newsArticle destructuring comes from the localizedData
  const isArticleTranslated = !!data.sanityNewsArticle.newsArticlePortableText[currentLanguage];

  function isNewsModuleWithSameContentType(module: { __typename: string } & ExtraModuleProperties) {
    return (
      module.__typename === 'SanityNewsModule' &&
      (module.newsContentTypes!.length === 0 ||
        module
          .newsContentTypes!.map(contentType => contentType._id)
          .includes(newsArticle.contentType._id))
    );
  }

  let firstModuleData = getFirstModuleBreadcrumbValuesByFunction(
    pages.nodes,
    module => isNewsModuleWithSameContentType(module) && !module.showOnlyAFewLatestArticles,
  );

  if (!firstModuleData) {
    firstModuleData = getFirstModuleBreadcrumbValuesByFunction(
      pages.nodes,
      isNewsModuleWithSameContentType,
    );
  }

  const { Portal } = usePortal();
  const [isShareCardVisible, setIsShareCardVisible] = useState(false);

  function openShareCard() {
    if (navigator.share) {
      navigator
        .share({
          title: newsArticle.title,
          url: window.location.href,
        })
        .catch(error => {
          Sentry.captureException(error);
          console.error(error);
        });
    } else {
      setIsShareCardVisible(true);
    }
  }

  const shareButton = (
    <GenericLink styleOnly className={styles.shareLink} onClick={openShareCard}>
      {t('news_article_page.share_link', 'Share')}
      <FaShare className={styles.iconRight}></FaShare>
    </GenericLink>
  );

  const becauseWeCareIcon = {
    plants: becauseWeCare_plants,
    people: becauseWeCare_people,
    planet: becauseWeCare_planet,
  };

  const becauseWeCarePillarTitle = {
    plants: t('news_article_page.because_we_care_pillars.plants', 'Plants'),
    people: t('news_article_page.because_we_care_pillars.people', 'People'),
    planet: t('news_article_page.because_we_care_pillars.planet', 'Planet'),
  };

  function getInitials(fullName: string) {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  return (
    <Layout transparentHeader={false}>
      <PageSEO
        defaultTitle={newsArticle.title}
        defaultDescription={newsArticle.extract}
        defaultImageUrl={
          newsArticle.image && newsArticle.image.asset
            ? newsArticle.image.asset.url + '?w=1200&h=630&fit=min'
            : undefined
        }
      />
      <section className={styles.section}>
        <div className={styles.topLinksContainer}>
          <div className={styles.pathContainer}>
            <div className={styles.path}>
              {firstModuleData && (
                <LocalizedLink to={firstModuleData.url} className={'titleParagraph ' + styles.link}>
                  {/* <FontAwesomeIcon icon="chevron-left" className={styles.iconLeft} /> */}
                  {firstModuleData.name}
                </LocalizedLink>
              )}
              <FaChevronRight className={styles.iconLeft}></FaChevronRight>
            </div>
            <div>
              <span className={'titleParagraph ' + styles.pathTitle}>{newsArticle.title}</span>
            </div>
          </div>

          {shareButton}
        </div>

        {!isArticleTranslated && (
          <div className={styles.untranslatedBarContainer}>
            <div className={styles.untranslatedBarTextContainer}>
              <p className={styles.untranslatedText}>
                {t(
                  'news_article_page.untranslated_article_bar.text1',
                  'This article is not available in your language.',
                )}
              </p>
              {firstModuleData && (
                <p className={styles.untranslatedText}>
                  {t(
                    'news_article_page.untranslated_article_bar.text2',
                    `You can read the English version below or see all available articles in {{languageName}}.`,
                    { languageName: LANG_TO_LANG_NAME[currentLanguage] },
                  )}
                </p>
              )}
            </div>
            {firstModuleData && (
              <div className={styles.seeTranslatedArticlesLinkContainer}>
                <LocalizedLink
                  to={firstModuleData?.url}
                  className={styles.seeTranslatedArticlesLink}
                >
                  {t(
                    'news_article_page.untranslated_article_bar.link_text',
                    `See articles in {{languageName}}`,
                    { languageName: LANG_TO_LANG_NAME[currentLanguage] },
                  )}
                  <FaChevronRight className={styles.seeTranslatedArticlesIcon} />
                </LocalizedLink>
              </div>
            )}
          </div>
        )}
        <div className={styles.imageWrapper}>
          <GatsbyImage
            alt={newsArticle.title}
            className={styles.imageContainer}
            imgClassName={styles.image}
            image={newsArticle.image.asset.gatsbyImageData}
            loading="eager"
          ></GatsbyImage>
        </div>

        {newsArticle.becauseWeCarePillars && newsArticle.becauseWeCarePillars?.length > 0 && (
          <div
            className={styles.becauseWeCarePillarsContainer}
            title={t(
              'news_article_page.because_we_care_pillars.info',
              'ASCENZA’s positioning is based on the pillars Plants, People and Planet. Click here to know more.',
            )}
          >
            {newsArticle.becauseWeCarePillars.map((pillar, i) => (
              <div className={styles.becauseWeCarePillar} key={i}>
                <img src={becauseWeCareIcon[pillar]} alt="" className={styles.becauseWeCareIcon} />
                <span className={styles.becauseWeCarePillarTitle}>
                  {becauseWeCarePillarTitle[pillar]}
                </span>
              </div>
            ))}
            <LocalizedLink
              className={styles.becauseWeCareInfoContainer}
              to={
                mediaSettings.becauseWeCarePage.__typename === 'SanityNewsArticle'
                  ? getReferenceUrl(
                      'SanityNewsArticle',
                      mediaSettings.becauseWeCarePage.slug.current,
                      mediaSettings.becauseWeCarePage.contentType.slug.current,
                    )
                  : getReferenceUrl('SanityPage', mediaSettings.becauseWeCarePage.slug.current)
              }
            >
              <FaQuestion className={styles.becauseWeCareInfoIcon} />
            </LocalizedLink>
          </div>
        )}
        <h1 className={styles.title}>{newsArticle.title}</h1>
        <div className={clsx(styles.detailsContainer, newsArticle.author && styles.withAuthor)}>
          {newsArticle.author && (
            <div className={styles.authorContainer}>
              <div
                className={clsx(
                  styles.authorImageContainer,
                  !newsArticle.author.image && styles.noAuthorImage,
                )}
              >
                {newsArticle.author.image ? (
                  <GatsbyImage
                    alt=""
                    image={newsArticle.author.image?.asset.gatsbyImageData}
                    className={styles.authorImage}
                  />
                ) : (
                  <div className={styles.noAuthorImageContainer}>
                    <span className={styles.authorInitials}>
                      {getInitials(newsArticle.author.name)}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.authorInfo}>
                <span className={styles.authorName}>{newsArticle.author.name}</span>
                <span className={styles.authorPosition}>{newsArticle.author.position}</span>
              </div>
            </div>
          )}
          <div
            className={clsx(
              styles.dateAndCategoryContainer,
              newsArticle.author && styles.withAuthor,
            )}
          >
            <div className={clsx(styles.tagContainer, newsArticle.author && styles.withAuthor)}>
              <FaTag className={styles.categoryIcon}></FaTag>
              <span className={styles.categoryTitle + ' titleParagraph'}>
                {newsArticle.contentType.title}
              </span>
              <span className={styles.categoryTitle + ' titleParagraph'}>
                {newsArticle.category.title}
              </span>
            </div>
            <span className={styles.date}>{newsArticle.date}</span>
          </div>
        </div>

        <div className={styles.body}>
          <BlockContent
            blocks={newsArticle.newsArticlePortableText}
            serializers={{
              marks: {
                genericLink: GenericLinkSerializer,
                pageLink: PageLinkSerializer,
                newsArticleLink: NewsArticleLinkSerializer,
              },
              types: {
                embeddedImage: EmbeddedImageSerializer,
                quote: QuoteSerializer,
                youtube: YoutubeSerializer,
                imageGallery: ImageGallerySerializer,
                resourceFile: ResourceFileSerializer,
                resourceFileReference: ResourceFileSerializer,
              },
            }}
          />
        </div>
        <GenericLink
          styleOnly
          className={styles.shareLink + ' ' + styles.bottomShareButton}
          onClick={openShareCard}
        >
          {t('news_article_page.share_link', 'Share')}
          <FaShare className={styles.iconRight}></FaShare>
        </GenericLink>

        {/* <ArticlesPagination>{entries}</ArticlesPagination> */}
        {isShareCardVisible && (
          <Portal>
            <div className={styles.overlay} onClick={() => setIsShareCardVisible(false)}>
              <div className={styles.shareCardWrapper} onClick={e => e.stopPropagation()}>
                <ShareCard></ShareCard>
              </div>
            </div>
          </Portal>
        )}
      </section>
    </Layout>
  );
};

export default withI18n(NewsArticlePage);
